import React from "react";
import { CmsContent, Container, Layout, PostNavigation, SocialsSharing } from "components";
import { Hero, Loader } from "components/ACF";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";
import { graphql } from "gatsby";

interface BlogPostTemplate {
  data: Queries.POST_BY_IDQuery;
}

const BlogPostTemplate = ({ data: { previous, next, post, wp } }: BlogPostTemplate): JSX.Element => {
  const { title, seo, acfLayout } = post;
  const {
    webAppSettings: {
      webAppSettings: { postSharingOptions },
    },
  } = wp;

  return (
    <Layout layoutType="post" pageTitle={title} seo={seo}>
      <>
        <Hero
          data={{
            content: `<h1>${post.title}</h1>`,
            compact: true,
            breadcrumbs: "below",
            image: post?.featuredImage?.node,
          }}
          location={seo?.breadcrumbs}
        />
        {acfLayout?.layout?.length > 0 ? (
          <Loader
            data={acfLayout.layout.map(({ ...layout }) => ({
              ...layout,
            }))}
            location={seo?.breadcrumbs}
          />
        ) : (
          <div className="post">
            {post.content && (
              <Container>
                {/* Using CmsContent here incase we're using shortcodes */}
                <CmsContent content={post.content} />
              </Container>
            )}
          </div>
        )}
        {postSharingOptions && <SocialsSharing socialItems={postSharingOptions} />}
        <PostNavigation prevLink={previous} nextLink={next} backLink="/blog/" backLinkText="All blog posts" />
      </>
    </Layout>
  );
};

export default WordPressPreview({ postType: "posts" })(BlogPostTemplate);

export const PostByIdQuery = graphql`
  query POST_BY_ID($id: String!, $previousPostId: String, $nextPostId: String) {
    post: wpPost(id: { eq: $id }) {
      ...PostFields
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
    wp {
      webAppSettings {
        webAppSettings {
          postSharingOptions
        }
      }
    }
  }
`;
